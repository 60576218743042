var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Рекомендации с ошибками")]),_c('recommendations-filters',{attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeField}}),_c('data-table',{ref:"dataTable",attrs:{"url":"stock-rating-feed","table-params":_vm.tableParams,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.analystName",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(recommendation.analystName)+" ")]}},{key:"item.ticker",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(recommendation.ticker)+" ")]}},{key:"item.analystCompany",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(recommendation.analystCompany)+" ")]}},{key:"item.recommendation",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("consensus")(recommendation.recommendation))+" ")]}},{key:"item.priceTarget",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(recommendation.priceTarget))+" ")]}},{key:"item.date",fn:function(ref){
var recommendation = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(recommendation.date))+" ")]}},{key:"item.type",fn:function(ref){
var recommendation = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center pa-1"},[_c('v-img',{attrs:{"src":("/img/recommendation/" + (recommendation.type) + ".png"),"width":"26","height":"26","contain":""}})],1)]}},{key:"item.actions",fn:function(ref){
var recommendation = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.importRecommendation(recommendation.id)}}},[_vm._v(" Импортировать ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.openEditModal(recommendation)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.confirmDelete(recommendation)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('recommendations-confirm-delete',{attrs:{"is-open":_vm.paramsConfirm.isOpen},on:{"close":function($event){_vm.paramsConfirm.isOpen = false},"delete":function($event){return _vm.deleteRecommendation(_vm.paramsConfirm.recommendationId)}}}),(_vm.activeRecommendation !== null)?_c('recommendations-edit',{attrs:{"recommendation":_vm.activeRecommendation},on:{"change-field":_vm.changeRecommendationField,"close":_vm.resetRecommendationFields,"update":_vm.updateRecommendation}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }